import { memo, useCallback } from 'react';
import "./index.scss";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const ParticleBackground = () => {
    const particlesInit = useCallback(async (engine) => {
        await loadSlim(engine);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
                fullScreen: { enable: false },
                background: { color: "transparent" },
                particles: {
                    number: { value: 50, density: { enable: true, area: 800 } },
                    color: { value: "#ffffff" },
                    shape: { type: "circle" },
                    opacity: { value: 0.5, random: true },
                    size: { value: 3, random: true },
                    move: { enable: true, speed: 2, direction: "none", random: false },
                    line_linked: { enable: true, distance: 150, color: "#ffffff", opacity: 0.4, width: 1 }
                },
                interactivity: {
                    events: {
                        onHover: { enable: true, mode: "repulse" },
                    },
                    modes: {
                        repulse: { distance: 100, duration: 0.4, line_linked: { opacity: 1 } },
                        push: { quantity: 4 }
                    }
                }
            }}
            className="particle-background"
        />
    );
};

export default memo(ParticleBackground);
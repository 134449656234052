import { useMemo } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import TopContainer from '../../components/topContainer';
import Button from '../../components/button';
import blogsTopBackground from "../../assets/images/blogs_top_background.png"
import eastArrow from "../../assets/images/east_arrow_blue.svg";
import news1 from "../../assets/images/news1.png";
import news2 from "../../assets/images/news2.png";
import news3 from "../../assets/images/news3.png";
import news4 from "../../assets/images/news4.png";
import news5 from "../../assets/images/news5.png";
import news6 from "../../assets/images/news6.png";
import Footer from '../../components/footer';

const Blogs = () => {

    const navigate = useNavigate();

    const topbarTitle = useMemo(() => 'Blogs and Recent News', []);
    const topbarDescription = useMemo(() => 'Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.', []);

    const newsItems = useMemo(() => [
        {
            id: '1',
            image: news1,
            date: 'April 05, 2024',
            content: "Generative AI Moves Beyond Chatbots: A New Era of Innovation",
        },
        {
            id: '2',
            image: news2,
            date: 'April 05, 2024',
            content: "The Rise of AI: Why It's Crucial for Business Success",
        },
        {
            id: '3',
            image: news3,
            date: 'April 05, 2024',
            content: "Coding by Conversation: The AI Revolution in Software Development",
        },
        {
            id: '4',
            image: news4,
            date: 'May 30, 2024',
            content: "Seconds Saved, Lives Changed: How AI is Revolutionizing Emergency...",
        },
        {
            id: '5',
            image: news5,
            date: 'April 03, 2024',
            content: "Tips for Spotting and Preventing Generative AI Scams",
        },
        {
            id: '6',
            image: news6,
            date: 'April 05, 2024',
            content: "GPT-Proof Assessments: Protecting Academic Integrity",
        },
    ], [])

    const handleBlogClick = (id) => {
        navigate(`/blogs/${id}`)
    }

    return (
        <div className='blogs-screen'>
            <TopContainer title={topbarTitle} description={topbarDescription} background={blogsTopBackground} />
            <div className="blogs-content-wrapper">
                <div className="blogs-content-container">
                    <div className="cards-container">
                        {newsItems.map((newsItem) => (
                            <div className="card" key={newsItem.id}>
                                <img src={newsItem.image} alt='' className='news-image' loading='lazy' />
                                <div className='news-content'>
                                    <div className="news-title-date">
                                        <div className='date'>{newsItem.date}</div>
                                        <div className="news-title">{newsItem.content}</div>
                                    </div>
                                    <Button label="Learn More" suffixIcon={eastArrow} variant='outlined' onClick={() => { handleBlogClick(newsItem.id) }} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blogs;
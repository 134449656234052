import './index.scss';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/navbar';
import westArrowWhite from "../../../assets/images/west_arrow_white.svg";
import blog6DetailImage1 from "../../../assets/images/blog6_detail_image_1.png";
import blog6DetailImage2 from "../../../assets/images/blog6_detail_image_2.png";
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import ScrollToTop from '../../../components/scrollToTop';

const Blog6 = () => {

    const navigation = useNavigate();

    return (
        <div className='blog-detail-screen'>
            <div className='blog-top-container'>
                <Navbar />
                <div className="blog-content-area">
                    <button className="topbar-button" onClick={() => { navigation(-1) }} >
                        <img src={westArrowWhite} alt='' loading='lazy' />
                        <span>Back to Blog</span>
                    </button>
                    <div className="topbar-title">
                        GPT-Proof Assessments: Protecting Academic Integrity
                    </div>
                    <div className="topbar-date">
                        April 05, 2025
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <div className="blog-content-wrapper">
                <div className="blog-content-container">
                    <div>
                        <p>
                            The rapid advancement of large language models (LLMs) has presented a new challenge for educators seeking to effectively assess student performance in online learning environments. As these AI assistants become increasingly adept at understanding and completing assessments across various modalities, it has become alarmingly easy for students to "game the system" and achieve high scores without demonstrating genuine mastery of the material.
                        </p>
                    </div>
                    <div>
                        <p>
                            This is not only a disservice to the students themselves, but also a threat to the integrity of the broader credentialing process. When students with suboptimal skills are able to transfer to higher institutions or enter the workforce based on inflated assessment scores, it undermines the value and reliability of those credentials for employers and the public.
                        </p>
                    </div>
                    <div>
                        <p>
                            With current online assessment mechanisms vulnerable to LLM-powered assistance, there is a clear need for universities and educators to identify new strategies to ensure the integrity of their evaluations. Here are several proven approaches institutions can leverage to make their assessments more resistant to AI-enabled cheating:
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog6DetailImage1} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="title">Pre and Post-Test Diagnostics</div>
                        <p>
                            Provide students with short, proctored diagnostic tests before and after the main assessment. This allows instructors to establish a baseline of the student's true abilities and knowledge gaps prior to the exam. For example, a 30-minute pre-test could cover core concepts and skills that will be evaluated on the larger assessment. By comparing the student's pre-test and post-test performance, instructors can identify areas where they may be over-relying on LLM assistance rather than demonstrating genuine mastery. The diagnostic test results could then be used as a multiplier on the final exam score - if a student scored 60% on the pre-test, their exam score of 85% would be adjusted to 51% (60% x 85%). This disincentivizes students from attempting to outsource the work to an AI.
                        </p>
                    </div>
                    <div>
                        <div className="title">
                            In-Person Assessments
                        </div>
                        <p>
                            Require students to complete assessments in a proctored, electronics-free environment. This could be on campus or at a designated testing center. By prohibiting access to the internet, notes, or digital aids, students are forced to rely solely on their own knowledge and critical thinking. Proctors can also closely monitor the students' activities to ensure no unauthorized assistance is being used. For instance, an in-person engineering exam could involve sketching circuit diagrams, solving math problems by hand, and explaining design decisions verbally - tasks that are difficult to outsource to a language model. The controlled setting and direct observation mitigate the risk of LLM-enabled cheating.
                        </p>
                    </div>
                    <div>
                        <div className="title">Oral Assessments</div>
                        <p>
                            Place a greater emphasis on real-time, interactive oral assessments rather than written assignments. This may involve students delivering presentations to their instructor and classmates over a video conferencing platform, with questions and follow-ups interspersed throughout. The spontaneous nature of these assessments makes it much harder for students to have an LLM generate content for them. Instructors can also ask probing questions to gauge the student's depth of understanding, rather than just their ability to regurgitate memorized information. For example, a marketing student might be pitched a new product concept and then defend their strategic decisions against challenging questions from the panel.
                        </p>
                    </div>
                    <div>
                        <div className="title">Proctored Remote Exams</div>
                        <p>
                            Partner with established proctoring services to securely administer remote assessments for students who cannot physically attend an in-class assessment. Proctoring centers typically use a combination of video monitoring, screen sharing, and biometric identification to ensure exam integrity. For instance, the ProctorU service requires students to present photo ID, scan their environment for prohibited items, and remain visible via webcam throughout the assessment. Any suspicious activity is flagged for the proctor to investigate. By replicating the controls of an in-person exam setting, universities can confidently evaluate remote students without concerns about LLM-enabled cheating.
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog6DetailImage2} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="title">Continuous Assessments</div>
                        <p>Rather than relying on a single high-stakes exam, distribute assessment points across multiple smaller assignments, quizzes, and projects throughout the course. This makes it exponentially more difficult for students to outsource the entire evaluation to an LLM. For a computer science course, this could involve weekly coding challenges, a midterm group project, and a final capstone presentation - all contributing to the overall grade. The consistent engagement and performance monitoring ensures students are steadily building their skills, not simply trying to game the system at the last minute.</p>
                    </div>
                    <div>
                        <div className="title">Employer Partnerships</div>
                        <p>Collaborate with industry partners to provide students with on-the-job training and assessment opportunities. This could include internships, apprenticeships, or project-based learning experiences embedded within the curriculum. In these authentic work environments, students must demonstrate their abilities through tangible outputs and behaviors that are much harder to artificially inflate with LLM assistance. Employers can also serve as additional proctors, observing student performance and providing feedback to the university. For example, engineering students might work in teams to develop a new product prototype for a manufacturing company, with the company's product managers evaluating their technical competence, collaboration, and problem-solving skills.</p>
                    </div>
                    <div>
                        <div className="title">Conclusion</div>
                        <p>The overarching goal of these strategies is to force students to showcase their own independent knowledge, critical thinking, and applied skills - areas where current language models still have significant limitations. By incorporating a diverse range of assessment modalities, universities can better ensure the integrity of their evaluation processes and the true preparedness of their graduates for the modern workforce.</p>
                        <p>As artificial intelligence continues to advance, the need for innovative, GPT-proof assessment approaches will only grow. By proactively addressing this challenge, educational institutions can uphold the credibility of their credentials and position their students for long-term success.</p>
                    </div>
                    <BlogsNews skipId="6" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blog6;
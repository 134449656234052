import { memo } from 'react';
import './index.scss';

const Button = ({ label, onClick = () => { }, suffixIcon, variant = "filled" }) => {

    return (
        <button className={`app-button ${variant}`} onClick={onClick}>
            <div>{label}</div>
            {suffixIcon && <div>
                <img src={suffixIcon} alt='' loading='lazy' />
            </div>}
        </button>
    );
};

export default memo(Button);
import { useMemo } from 'react';
import './index.scss';
import aboutTopBackground from "../../assets/images/about_top_background.png";
import TopContainer from '../../components/topContainer';
import Expertise from './expertise';
import OurTeam from './ourTeam';
import BlogsNews from '../../components/blogsNews';
import Footer from '../../components/footer';

const AboutUs = () => {

    const topbarTitle = useMemo(() => 'About Us', []);
    const topbarDescription = useMemo(() => 'At Sentellent, we enable businesses to unlock new possibilities and achieve unprecedented growth through our cutting-edge Generative AI solutions. We harness advanced AI to craft innovative technologies that tackle complex challenges, drive efficiency, and deliver measurable results across diverse industries', []);

    return (
        <div className='aboutus-screen'>
            <TopContainer title={topbarTitle} description={topbarDescription} background={aboutTopBackground} />
            <div className="about-content-wrapper">
                <div className="about-content-container">
                    <Expertise />
                    <div className="divider"></div>
                    <OurTeam />
                    <div className="divider"></div>
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
import { memo } from 'react';
import './index.scss';
import Navbar from '../navbar';
import Button from '../button';
import eastArrowWhite from '../../assets/images/east_arrow_white.svg';
import ScrollToTop from '../scrollToTop';
import ParticlesBackground from '../particlesBackground';

const TopContainer = ({ background, routes = [], title, description, buttonTitle, onButtonClick = () => { } }) => {

    return (
        <div className='app-top-container' style={{ backgroundImage: `url(${background})` }}>
            <ParticlesBackground />
            <Navbar />
            <div className='top-content-area'>
                {routes && routes.length > 0 && (
                    <div className="route-detail">
                        {routes.map((item, index) => (
                            <div key={index.toString()}>
                                <span>{item}</span>
                                {index < routes.length - 1 && <span className='seperator'>/</span>}
                            </div>
                        ))}
                    </div>
                )}
                <div className='topbar-title'>
                    {title}
                </div>
                <div className='topbar-description'>
                    {description}
                </div>
                {buttonTitle && <div className='topbar-button'>
                    <Button label={buttonTitle} suffixIcon={eastArrowWhite} onClick={onButtonClick} />
                </div>}
            </div>
            <ScrollToTop />
        </div>
    );
};

export default memo(TopContainer);
import { useMemo } from 'react';
import './index.scss';
import jamminTopBackground from "../../../assets/images/jammin_top_background.png"
import jamminFeature1 from "../../../assets/images/jammin_feature_1.png"
import jamminFeature2 from "../../../assets/images/jammin_feature_2.png"
import jamminFeature3 from "../../../assets/images/jammin_feature_3.png"
import jamminFeature4 from "../../../assets/images/jammin_feature_4.png"
import attractionsIcon from "../../../assets/images/attractions_icon.svg"
import biaIcon from "../../../assets/images/bia_icon.svg"
import autoplayIcon from "../../../assets/images/autoplay_icon.svg"
import googleHomeDevices from "../../../assets/images/google_home_devices.svg"
import TopContainer from '../../../components/topContainer';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import KeyFeatures from '../components/keyfeatures';
import Industries from '../components/industries';

const JamminAi = () => {

    const topbarTitle = useMemo(() => 'Jammin.ai - Your AI-Powered Music Companion', []);
    const topbarDescription = useMemo(() => 'Jammin.ai transforms music practice and performance with AI-driven chord and lyric detection for any YouTube song. Supporting over 50 languages, it simplifies musical exploration for all skill levels. Whether practicing solo, teaching a class, or preparing for a gig, Jammin.ai delivers instant access to accurate chords and lyrics. Its intuitive interface streamlines the learning process, eliminating tedious transcriptions and making playing your favourite songs effortless.', []);

    const featuresData = useMemo(() => [
        {
            icon: attractionsIcon,
            title: "Real-Time Chords & Lyrics Sync",
            description: "Chords and lyrics are displayed in sync with YouTube videos, eliminating the need to pause or switch windows. Stay focused and play along seamlessly.",
            image: jamminFeature1
        },
        {
            icon: biaIcon,
            title: "Dynamic Timing & Chord Progression Display",
            description: "Chords appear precisely when you need to play them, transitioning smoothly with the music to help you stay on beat and master tricky progressions.",
            image: jamminFeature2
        },
        {
            icon: autoplayIcon,
            title: "Looping for Focused Practice",
            description: "Isolate and repeat specific sections of a song to master challenging riffs, solos, or tricky transitions with ease.",
            image: jamminFeature3
        },
        {
            icon: googleHomeDevices,
            title: "Curated Playlists for Personalized Practice",
            description: "Save your favorite songs, organize them into playlists, and create custom setlists for rehearsals and performances.",
            image: jamminFeature4
        },
    ], [])

    return (
        <div className='jammin-screen'>
            <TopContainer
                background={jamminTopBackground}
                routes={["Products", "Jammin.ai"]}
                title={topbarTitle}
                description={topbarDescription}
                buttonTitle="Visit Website"
                onButtonClick={() => window.open("https://jammin.ai/", "_blank")}
            />
            <div className="jammin-content-container">
                <div className="content-wrapper">
                    <KeyFeatures title='Key Features of Jammin.ai' data={featuresData} />
                </div>
            </div>
            <div className="industries-wrapper">
                <Industries />
            </div>
            <div className="jammin-content-container">
                <div className="content-wrapper">
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default JamminAi;
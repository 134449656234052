import { memo } from 'react';
import './index.scss';

const KeyFeatures = ({ title = "", data = [] }) => {

  return (
    <div className='product-keyfeatures'>
      <div className='title' >{title}</div>
      <div className="keyfeatures-container">
        {data.map((item, index) => (
          <div key={index.toString()} className="feature-row">
            <div className="row-left-column">
              <img src={item.icon} alt='' loading='lazy' />
              <div className="row-title">{item.title}</div>
              <div className="row-description">{item.description}</div>
            </div>
            <div className="row-right-column">
              <img src={item.image} alt='' loading='lazy' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(KeyFeatures);
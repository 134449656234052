import { memo, useMemo, useState, useRef, useEffect } from 'react';
import './index.scss';
import eastArrow from "../../../assets/images/east_arrow_white.svg";
import campusInsight from "../../../assets/images/campus_insight.png";
import emsPal from "../../../assets/images/ems_pal.png";
import jamminAi from "../../../assets/images/jammin_ai.png";
import campaignsPlanet from "../../../assets/images/campaigns_planet.png";
// import easyDonate from "../../../assets/images/easy_donate.png";
import westArrowWhite from "../../../assets/images/west_arrow_white.svg";
import eastArrowWhite from "../../../assets/images/east_arrow_white.svg";

const Products = () => {

    const scrollRef = useRef(null);
    const [prevDisabled, setPrevDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const productItems = useMemo(() => [
        {
            name: "Campus Insights",
            summary: "Campus Insights is a comprehensive suite of Intelligent AI solutions that improves student engagement, provides actionable insights, and drives institutional success in the digital age.",
            image: campusInsight,
            path: "https://campusinsights.ai/"
        },
        {
            name: "EMSPal",
            summary: "EMSPal is an AI-powered chat assistant that empowers EMTs and paramedics with instant access to critical protocols, enabling faster and more informed emergency responses.",
            image: emsPal,
            path: "https://emspal.com/"
        },
        {
            name: "Jammin.ai",
            summary: "Jammin.ai is more than just an AI music assistant; it's a versatile platform designed to make music accessible to everyone. Whether you're a pro or a beginner, its tools help you spark creativity, learn, and explore new possibilities, making it easy to jam out to your favorite songs.",
            image: jamminAi,
            path: "https://www.jammin.ai/"
        },
        {
            name: "Campaigns Planet",
            summary: "The CampaignsPlanet AI-driven Marketing Automation platform is designed to revolutionize the management of marketing campaigns.",
            image: campaignsPlanet,
            path: "https://campaignsplanet.com/"
        },
        // {
        //     name: "easy-donate",
        //     summary: "easy-donate leverages AI to empower nonprofits in their fundraising efforts.",
        //     image: easyDonate,
        //     path: "https://www.easy-donate.com/"
        // },
    ], [])

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);

    const handleNavigate = (path) => {
        if (path) {
            window.open(path, "_blank");
        }
    }

    const checkScrollPosition = () => {
        setTimeout(() => {
            const scrollLeft = scrollRef.current.scrollLeft;
            const scrollWidth = scrollRef.current.scrollWidth;
            const containerWidth = scrollRef.current.offsetWidth;

            setPrevDisabled(scrollLeft === 0);
            setNextDisabled(scrollLeft + containerWidth >= scrollWidth);
        }, 500)
    };

    const handlePrev = () => {
        if (!prevDisabled) {
            scrollRef.current.scrollBy({
                left: -window.innerWidth * 0.87,
                behavior: 'smooth',
            });
            checkScrollPosition();
        }
    };

    const handleNext = () => {
        if (!nextDisabled) {
            scrollRef.current.scrollBy({
                left: window.innerWidth * 0.87,
                behavior: 'smooth',
            });
            checkScrollPosition();
        }
    };

    useEffect(() => {
        checkScrollPosition();
    }, [])

    return (
        <div className='home-products'>
            <div className='title'>Our Products</div>
            <div className='scroll-container' ref={scrollRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} >
                <div className='images-container'>
                    {productItems.map((item, index) => (
                        <div className='image-card' key={index.toString()} >
                            <div className='image-content'>
                                <div className="card-title">
                                    {item.name}
                                </div>
                                <div className="hovered-content">
                                    <div className="hovered-content-title">
                                        {item.name}
                                    </div>
                                    <div className="hovered-content-summary">
                                        {item.summary}
                                    </div>
                                    <button onClick={() => { handleNavigate(item.path) }} >
                                        <span>Visit Website</span>
                                        <img src={eastArrow} alt='' loading='lazy' />
                                    </button>
                                </div>
                            </div>
                            <img className='background-image' src={item.image} alt='' loading='lazy' draggable={false} />
                        </div>
                    ))}
                </div>
            </div>
            <div className='arrow-container'>
                <div className={`arrow ${prevDisabled ? 'inactive' : ''} `} onClick={handlePrev} >
                    <img src={westArrowWhite} alt='' loading='lazy' />
                </div>
                <div className={`arrow ${nextDisabled ? 'inactive' : ''} `} onClick={handleNext} >
                    <img src={eastArrowWhite} alt='' loading='lazy' />
                </div>
            </div>
        </div>
    );
};

export default memo(Products);
import { memo, useMemo } from 'react';
import './index.scss';
import lockReset from "../../../assets/images/lock_reset.svg";
import clinicalNotes from "../../../assets/images/clinical_notes.svg";
import cloudLock from "../../../assets/images/cloud_lock.svg";

const GradeSecurity = () => {

    const cardItems = useMemo(() => [
        {
            name: "Enterprise-Grade Security",
            image: lockReset
        },
        {
            name: "Data Integrity and Validation",
            image: clinicalNotes
        },
        {
            name: "Encrypted in Transit and at Rest",
            image: cloudLock
        },
    ], [])

    return (
        <div className='home-grade-security'>
            <div className="content-area">
                <div className='title'>Enterprise-Grade Security</div>
                <div className="description">The security and compliance needs of our customers are a top priority at Sentellent. We continuously work on making sure we live up to the highest industry standards.</div>
                <div className="cards-container">
                    {cardItems.map((cardItem, cardItemindex) => (
                        <div className="card" key={cardItemindex.toString()}>
                            <img src={cardItem.image} alt='' loading='lazy' />
                            <div>{cardItem.name}</div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(GradeSecurity);
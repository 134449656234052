import { useMemo } from 'react';
import './index.scss';
import campaignsPlanetTopBackground from "../../../assets/images/campaigns_planet_top_background.png"
import campaignsPlanetFeature1 from "../../../assets/images/campaigns_planet_feature_1.png"
import campaignsPlanetFeature2 from "../../../assets/images/campaigns_planet_feature_2.png"
import campaignsPlanetFeature3 from "../../../assets/images/campaigns_planet_feature_3.png"
import campaignsPlanetFeature4 from "../../../assets/images/campaigns_planet_feature_4.png"
import donutSmall from "../../../assets/images/donut_small.svg"
import bookSpark from "../../../assets/images/book_spark.svg"
import psychologyAlt from "../../../assets/images/psychology_alt.svg"
import barChart from "../../../assets/images/bar_chart.svg"
import TopContainer from '../../../components/topContainer';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import KeyFeatures from '../components/keyfeatures';
import Industries from '../components/industries';

const CampaignsPlanet = () => {

    const topbarTitle = useMemo(() => 'CampaignsPlanet: The AI Platform Tailored for Your Marketing Campaigns', []);
    const topbarDescription = useMemo(() => 'Transform your marketing with CampaignsPlanet, the AI-powered platform designed for efficiency and impact. Effortlessly create personalized content, manage multi-channel campaigns, and leverage AI for precise audience segmentation and predictive analytics. Leverage premium assets to enhance your campaigns, track performance with comprehensive analytics, and optimize your strategy with professional editing tools. CampaignsPlanet empowers you to achieve more, in less time, with smarter marketing.', []);

    const featuresData = useMemo(() => [
        {
            icon: donutSmall,
            title: "AI-Powered Content Creation",
            description: "Elevate your campaigns with high-quality, AI-curated stock images and videos tailored to your industry, audience, and campaign goals, ensuring maximum engagement.",
            image: campaignsPlanetFeature1
        },
        {
            icon: bookSpark,
            title: "Effortless Customization",
            description: "Fine-tune every aspect of your posts with an intuitive editor, adjusting fonts, colors, layouts, and adding unique icons to perfectly match your brand and messaging.",
            image: campaignsPlanetFeature2
        },
        {
            icon: psychologyAlt,
            title: "Strategic Scheduling",
            description: "Optimize your reach by scheduling posts for peak engagement times across X (Twitter) and LinkedIn, ensuring maximum visibility and impact.",
            image: campaignsPlanetFeature3
        },
        {
            icon: barChart,
            title: "Performance Analytics",
            description: "Analyze the effectiveness of your campaigns with a comprehensive metrics dashboard, visualizing key performance indicators to help refine your strategy and maximize ROI.",
            image: campaignsPlanetFeature4
        },
    ], [])

    return (
        <div className='campaigns-planet-screen'>
            <TopContainer
                background={campaignsPlanetTopBackground}
                routes={["Products", "CampaignsPlanet"]}
                title={topbarTitle}
                description={topbarDescription}
                buttonTitle="Visit Website"
                onButtonClick={() => window.open("https://campaignsplanet.com/", "_blank")}
            />
            <div className="campaigns-planet-content-container">
                <div className="content-wrapper">
                    <KeyFeatures title='Key Features of CampaignsPlanet' data={featuresData} />
                </div>
            </div>
            <div className="industries-wrapper">
                <Industries />
            </div>
            <div className="campaigns-planet-content-container">
                <div className="content-wrapper">
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CampaignsPlanet;
import { useMemo } from 'react';
import './index.scss';
import supplychainTopBackground from "../../../assets/images/supplychain_top_background.png";
// import campusInsight from "../../../assets/images/campus_insight.png"
// import campaignsPlanet from "../../../assets/images/campaigns_planet.png"
import TopContainer from '../../../components/topContainer';
import Challenges from '../components/challenges';
import Solutions from '../components/solutions';
// import RelatedProducts from '../components/relatedProducts';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';

const SupplyChain = () => {

    const topbarTitle = useMemo(() => 'AI-Driven Supply Chain Insights', []);
    const topbarDescription = useMemo(() => 'Our mission is to transform supply chains by leveraging AI to optimize demand forecasting, predict risks, and provide real-time visibility. We are dedicated to automating processes and enhancing decision-making, driving efficiency, cost savings, and resilience across the entire supply chain.', []);

    const challenges = useMemo(() => [
        {
            title: "Demand Forecasting & Inventory Management ",
            description: "Inaccurate demand predictions lead to stockouts, overstocking, and supply chain inefficiencies, impacting profitability and customer satisfaction."
        },
        {
            title: "Supply Chain Disruptions & Risk Management",
            description: "Natural disasters, geopolitical issues, and unexpected supplier failures cause delays, increased costs, and operational bottlenecks."
        },
        {
            title: "Lack of Real-Time Visibility",
            description: "Many supply chains struggle with limited end-to-end visibility, making it difficult to track shipments, monitor supplier performance, and respond to disruptions proactively."
        },
        {
            title: "Manual & Inefficient Processes",
            description: "Paper-based workflows and manual decision-making slow down operations, increasing errors and reducing overall supply chain efficiency."
        },
    ], [])

    const solutions = useMemo(() => [
        {
            title: "AI-Powered Demand Forecasting",
            description: "Machine learning models analyze historical data, market trends, and external factors to improve demand predictions, reducing inventory waste and optimizing stock levels."
        },
        {
            title: "Predictive Risk & Disruption Management",
            description: "AI-driven risk analysis identifies potential disruptions before they happen, allowing companies to proactively adjust sourcing, logistics, and supplier strategies."
        },
        {
            title: "Real-Time Supply Chain Visibility",
            description: "AI-powered tracking systems and IoT integration provide end-to-end visibility, enabling companies to monitor shipments, optimize routes, and respond to delays in real time."
        },
        {
            title: "Process Automation & Intelligent Decision-Making",
            description: "AI automates order processing, warehouse operations, and procurement, reducing human error, improving efficiency, and accelerating workflows."
        },
    ], [])

    // const productItems = useMemo(() => [
    //     {
    //         name: "Campus Insights",
    //         image: campusInsight
    //     },
    //     {
    //         name: "Campaigns Planet",
    //         image: campaignsPlanet
    //     },
    // ], [])

    return (
        <div className='supply-chain-screen'>
            <TopContainer background={supplychainTopBackground} routes={["Industries", "Supply Chain"]} title={topbarTitle} description={topbarDescription} />
            <Challenges data={challenges} />
            <Solutions data={solutions} />
            <div className="supply-chain-content-container">
                <div className="content-wrapper">
                    {/* <RelatedProducts data={productItems} /> */}
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SupplyChain;
import { useMemo } from 'react';
import './index.scss';
import mediaEntertainmentTopBackground from "../../../assets/images/media_entertainment_top_background.png";
import jamminAi from "../../../assets/images/jammin_ai.png"
import campaignsPlanet from "../../../assets/images/campaigns_planet.png"
import TopContainer from '../../../components/topContainer';
import Challenges from '../components/challenges';
import Solutions from '../components/solutions';
import RelatedProducts from '../components/relatedProducts';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';

const MediaEntertainment = () => {

    const topbarTitle = useMemo(() => 'AI-Powered Innovation for Media, Entertainment & Music', []);
    const topbarDescription = useMemo(() => 'Our mission is to transform media, entertainment, and music by making creativity more accessible through AI. We equip storytellers, content creators, and musicians with advanced tools to generate original concepts, automate tasks, and unlock new artistic expression—enabling them to bring visions to life and captivate audiences worldwide.', []);

    const challenges = useMemo(() => [
        {
            title: "Creative Block and Inefficient Workflow",
            description: "Artists, songwriters, and creators often struggle with obstacles like creative blocks and inefficient workflows, disrupting their creative process."
        },
        {
            title: "Ineffective Advertising & Audience Targeting",
            description: "Reaching the right audience with the right message remains a significant challenge for brands and media companies, leading to wasted ad spend and limited ROI."
        },
        {
            title: "Fragmented Media Consumption",
            description: "Users engage with content across multiple platforms, making it difficult to track preferences and deliver a seamless experience."
        },
        {
            title: "Lack of Data-Driven Decision Making",
            description: "Many companies struggle to analyze audience behavior effectively, leading to missed opportunities for engagement and content optimization."
        },
    ], [])

    const solutions = useMemo(() => [
        {
            title: "Empowering Creativity",
            description: "AI-powered tools assist both amateurs and professionals in the creative process, helping them generate ideas and improve their work."
        },
        {
            title: "Optimizing Advertising Strategies",
            description: "By analyzing user media consumption patterns, we help brands serve more relevant and effective ads."
        },
        {
            title: "Enhancing User Experience",
            description: "AI-driven insights identify user challenges, improving content recommendations and overall engagement."
        },
        {
            title: "Data-Driven Content & Strategy",
            description: "Our platform provides actionable insights into audience behavior, enabling media companies to refine content and optimize user experiences."
        },
    ], [])

    const productItems = useMemo(() => [
        {
            name: "Jammin.ai",
            summary: "Jammin.ai is more than just an AI music assistant; it's a versatile platform designed to make music accessible to everyone. Whether you're a pro or a beginner, its tools help you spark creativity, learn, and explore new possibilities, making it easy to jam out to your favorite songs.",
            image: jamminAi,
            path: "https://www.jammin.ai/"
        },
        {
            name: "Campaigns Planet",
            summary: "The CampaignsPlanet AI-driven Marketing Automation platform is designed to revolutionize the management of marketing campaigns.",
            image: campaignsPlanet,
            path: "https://campaignsplanet.com/"
        },
    ], [])

    return (
        <div className='media-screen'>
            <TopContainer background={mediaEntertainmentTopBackground} routes={["Industries", "Media & Entertainment"]} title={topbarTitle} description={topbarDescription} />
            <Challenges data={challenges} />
            <Solutions data={solutions} />
            <div className="media-content-container">
                <div className="content-wrapper">
                    <RelatedProducts data={productItems} />
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MediaEntertainment;
import { memo } from 'react';
import './index.scss';
import doneAll from "../../../../assets/images/done_all.svg"

const Solutions = ({ data = [] }) => {

    return (
        <div className='industry-solutions'>
            <div className="content-wrapper">
                <div className='title'>Our Solution</div>
                <div className="cards-container">
                    {data.map((item, index) => (
                        <div className='card' key={index.toString()}>
                            <img src={doneAll} alt='' loading='lazy' />
                            <div className="card-title">{item.title}</div>
                            <div className="card-description">{item.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default memo(Solutions);
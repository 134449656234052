import { memo, useMemo } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../button';
import eastArrow from "../../assets/images/east_arrow_blue.svg";
import news1 from "../../assets/images/news1.png";
import news2 from "../../assets/images/news2.png";
import news3 from "../../assets/images/news3.png";
import news4 from "../../assets/images/news4.png";
import news5 from "../../assets/images/news5.png";
import news6 from "../../assets/images/news6.png";

const BlogsNews = ({ skipId = null }) => {

    const navigate = useNavigate();

    const newsItems = useMemo(() => [
        {
            id: '1',
            image: news1,
            date: 'April 05, 2024',
            content: "Generative AI Moves Beyond Chatbots: A New Era of Innovation",
        },
        {
            id: '2',
            image: news2,
            date: 'April 05, 2024',
            content: "The Rise of AI: Why It's Crucial for Business Success",
        },
        {
            id: '3',
            image: news3,
            date: 'April 05, 2024',
            content: "Coding by Conversation: The AI Revolution in Software Development",
        },
        {
            id: '4',
            image: news4,
            date: 'May 30, 2024',
            content: "Seconds Saved, Lives Changed: How AI is Revolutionizing Emergency...",
        },
        {
            id: '5',
            image: news5,
            date: 'April 03, 2024',
            content: "Tips for Spotting and Preventing Generative AI Scams",
        },
        {
            id: '6',
            image: news6,
            date: 'April 05, 2024',
            content: "GPT-Proof Assessments: Protecting Academic Integrity",
        },
    ], []);

    const handleBlogClick = (id) => {
        navigate(`/blogs/${id}`)
    }

    return (
        <div className='blogs-recent-news'>
            <div className='title'>Blogs and Recent News</div>
            <div className="cards-container">
                {newsItems
                    .filter((item) => item.id !== skipId)
                    .slice(0, 3)
                    .map((newsItem) => (
                        <div className="card" key={newsItem.id}>
                            <img src={newsItem.image} alt='' className='news-image' loading='lazy' />
                            <div className='news-content'>
                                <div className="news-title-date">
                                    <div className='date'>{newsItem.date}</div>
                                    <div className="news-title">{newsItem.content}</div>
                                </div>
                                <Button label="Learn More" suffixIcon={eastArrow} variant='outlined' onClick={() => { handleBlogClick(newsItem.id) }} />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default memo(BlogsNews);
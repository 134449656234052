import './index.scss';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/navbar';
import westArrowWhite from "../../../assets/images/west_arrow_white.svg";
import blog4DetailImage1 from "../../../assets/images/blog4_detail_image_1.png";
import blog4DetailImage2 from "../../../assets/images/blog4_detail_image_2.png";
import blog4DetailImage3 from "../../../assets/images/blog4_detail_image_3.png";
import blog4DetailImage4 from "../../../assets/images/blog4_detail_image_4.png";
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import ScrollToTop from '../../../components/scrollToTop';

const Blog4 = () => {

    const navigation = useNavigate();

    return (
        <div className='blog-detail-screen'>
            <div className='blog-top-container'>
                <Navbar />
                <div className="blog-content-area">
                    <button className="topbar-button" onClick={() => { navigation(-1) }} >
                        <img src={westArrowWhite} alt='' loading='lazy' />
                        <span>Back to Blog</span>
                    </button>
                    <div className="topbar-title">
                        Seconds Saved, Lives Changed: How AI is Revolutionizing Emergency Medical Services
                    </div>
                    <div className="topbar-date">
                        May 30, 2024
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <div className="blog-content-wrapper">
                <div className="blog-content-container">
                    <p>
                        Artificial intelligence (AI) is rapidly transforming the medical field, ushering in a new era of possibilities for improved diagnosis, treatment, and patient care. From analyzing medical images for early disease detection to assisting in complex surgeries, AI is making significant strides across various healthcare specialties.
                    </p>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog4DetailImage1} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <p>
                            In the high-pressure world of Emergency Medical Services (EMS), every second counts. The ability to access accurate, location-specific medical protocols instantly can be the difference between life and death. EMTs and paramedics often operate in multiple jurisdictions, each with its own set of proprietary medical protocols. Keeping track of these varying guidelines and subtle differences can be challenging, leading to potential delays in decision-making and treatment. The need to manually search for protocol information not only consumes valuable time but also increases the risk of errors in critical situations.
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog4DetailImage2} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="title">
                            Introducing a Revolutionary Tool: The AI-Powered Chat Assistant for EMS
                        </div>
                        <p>
                            Introducing AI-powered chat assistant—a revolutionary tool that enables EMS personnel to quickly and efficiently access and understand the local complex protocol information, ensuring that EMTs and paramedics always have the right information at their fingertips. By equipping EMTs and paramedics with this innovative tool, we can significantly enhance patient care, improve operational efficiency, and reduce the risk of errors in emergency situations. Every second counts, and AI is here to ensure those seconds translate into saving lives.
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog4DetailImage3} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className='list'>
                            <div className="title">Key Features Empowering Your Team:</div>
                            <div className="list-row">
                                <div className="s-no">1.</div>
                                <div>
                                    <p>
                                        Real-Time, Location-Specific Access: Instantly access the most up-to-date protocols relevant to your current location, ensuring you're always following the right guidelines.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div className="s-no">2.</div>
                                <div>
                                    <p>
                                        <span>Natural Language Processing: </span>Use simple language to ask questions and receive clear, concise answers to protocol-related queries. No more navigating complex documents!
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div className="s-no">3.</div>
                                <div>
                                    <p>
                                        <span>Geolocation Integration: </span>Select your location to ensure you have the most relevant information at your fingertips.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog4DetailImage4} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">Benefits: Enhanced Care, Increased Efficiency, and Reduced Errors</div>
                            <div className='subtitle' >Our AI-powered chat assistant offers a multitude of benefits for EMS teams:</div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Enhanced Patient Care: </span>Spend more time focusing on life-saving actions by readily accessing the information you need. Make informed decisions quickly with accurate and up-to-date protocol guidance.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Increased Efficiency: </span>Reduce the time spent on administrative tasks and protocol searches, improving response times and overall operational efficiency.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Reduced Errors: </span>Minimize the risk of human error by ensuring adherence to the correct protocols, ultimately enhancing the quality of care provided to patients.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Ease of Use: </span>The intuitive interface ensures a smooth learning curve for all EMS professionals, accessible on mobile devices, tablets, and computers for maximum flexibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>
                            While AI can be a powerful tool, it's important to remember that it cannot replace the critical role of human judgment and expertise in emergency medical care. The final decisions regarding patient treatment should always be made by qualified medical professionals.
                        </p>
                    </div>
                    <BlogsNews skipId="4" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blog4;
import { memo } from 'react';
import './index.scss';
import aboutUsMap from "../../../assets/images/about_us_map.png";

const OurTeam = () => {

    return (
        <div className='about-our-team'>
            <div className='title-container'>
                <div className='title-container-title'>Our Team</div>

                <p className='title-container-description'>
                    At Sentellent, we are driven by a world-class team of seasoned executives and industry experts with deep expertise in <span>AI/ML, software development, cutting-edge technology, and business strategy.</span> With decades of experience at Fortune 50 companies, our leadership team has a proven track record of delivering innovative, high-impact solutions at a global scale.
                </p>
            </div>
            <div className='cards-container'>
                <div className="card">
                    <div className="card-title">Global Talent</div>
                    <div className="card-description">Our global team brings together top-tier talent from across the world, working around the clock to develop <span>robust, high-performance AI-driven solutions.</span> By leveraging a truly international workforce, we ensure agility, scalability, and the ability to provide seamless support—giving you a <span>strategic advantage</span> in an ever-evolving market.</div>
                </div>
                <div className="card">
                    <div className="card-title">Diverse Expertise</div>
                    <div className="card-description">Our team's extensive experience across multiple industries and technology domains allows us to <span>deeply understand your unique business challenges.</span> Whether you're looking to optimize operations, enhance customer experiences, or drive innovation, we craft solutions tailored to your specific needs—ensuring real impact and long-term success.</div>
                </div>
            </div>
            <div className="map-card">
                <img src={aboutUsMap} alt='' loading='lazy' />
            </div>
            <div className="footer-text">At Sentellent, we don't just build AI solutions—we engineer the <span>future of intelligent technology</span>, helping businesses stay ahead in an era of digital transformation.</div>
        </div>
    );
};

export default memo(OurTeam);
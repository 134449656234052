import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./modules/home";
import Education from "./modules/industrydetails/education";
import HealthCare from "./modules/industrydetails/healthCare";
import SupplyChain from "./modules/industrydetails/supplyChain";
import MediaEntertainment from "./modules/industrydetails/mediaEntertainment";
import CampusInsights from "./modules/productsdetails/campusInsights";
import EmsPal from "./modules/productsdetails/emspal";
import JamminAi from "./modules/productsdetails/jamminai";
import CampaignsPlanet from "./modules/productsdetails/campaignsPlanet";
// import EasyDonate from "./modules/productsdetails/easyDonate";
import AboutUs from "./modules/about";
import Blogs from "./modules/blogs";
import Contactus from "./modules/contact";
import Blog1 from "./modules/blogs/blogDetail/blog1";
import Blog2 from "./modules/blogs/blogDetail/blog2";
import Blog3 from "./modules/blogs/blogDetail/blog3";
import Blog4 from "./modules/blogs/blogDetail/blog4";
import Blog5 from "./modules/blogs/blogDetail/blog5";
import Blog6 from "./modules/blogs/blogDetail/blog6";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/industries/education" element={<Education />} />
                <Route path="/industries/healthcare-industry" element={<HealthCare />} />
                <Route path="/industries/supply-chain" element={<SupplyChain />} />
                <Route path="/industries/media-and-entertainment" element={<MediaEntertainment />} />

                <Route path="/products/campus-insights" element={<CampusInsights />} />
                <Route path="/products/emspal" element={<EmsPal />} />
                <Route path="/products/jammin-ai" element={<JamminAi />} />
                <Route path="/products/campaigns-planet" element={<CampaignsPlanet />} />
                {/* <Route path="/products/easy-donate" element={<EasyDonate />} /> */}

                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/1" element={<Blog1 />} />
                <Route path="/blogs/2" element={<Blog2 />} />
                <Route path="/blogs/3" element={<Blog3 />} />
                <Route path="/blogs/4" element={<Blog4 />} />
                <Route path="/blogs/5" element={<Blog5 />} />
                <Route path="/blogs/6" element={<Blog6 />} />
                <Route path="/contact-us" element={<Contactus />} />
            </Routes>
        </Router>
    )
}

export default App;
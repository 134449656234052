import { useMemo } from 'react';
import './index.scss';
import campusInsightsTopBackground from "../../../assets/images/campus_insights_top_background.png"
import campusInsightsFeature1 from "../../../assets/images/campus_insights_feature_1.png"
import campusInsightsFeature2 from "../../../assets/images/campus_insights_feature_2.png"
import campusInsightsFeature3 from "../../../assets/images/campus_insights_feature_3.png"
import campusInsightsFeature4 from "../../../assets/images/campus_insights_feature_4.png"
import donutSmall from "../../../assets/images/donut_small.svg"
import bookSpark from "../../../assets/images/book_spark.svg"
import psychologyAlt from "../../../assets/images/psychology_alt.svg"
import comicBubble from "../../../assets/images/comic_bubble.svg"
import TopContainer from '../../../components/topContainer';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import KeyFeatures from '../components/keyfeatures';
import Industries from '../components/industries';

const CampusInsights = () => {

    const topbarTitle = useMemo(() => 'From Enrollment to Graduation AI That Guides Every Step', []);
    const topbarDescription = useMemo(() => 'Campus Insights delivers tailored AI-driven solutions that enhance student engagement, retention, and success throughout their academic journey, from enrollment to graduation. Our seamless integration and personalized support ensure a student-first experience, resulting in a more engaging and effective learning environment.', []);

    const featuresData = useMemo(() => [
        {
            icon: donutSmall,
            title: "Tailored Solutions for Institutional Success",
            description: "We customize our AI-driven tools to align with your institution's unique needs, ensuring effortless integration and maximum impact. Enhance efficiency, engagement, and student success with a solution built just for you.",
            image: campusInsightsFeature1
        },
        {
            icon: bookSpark,
            title: "AI-Powered Student Engagement",
            description: "Campus Insights engages students from application to graduation, using AI-driven analytics to identify top applicants and support at-risk students. With smart form assistance, real-time tracking, and automated.",
            image: campusInsightsFeature2
        },
        {
            icon: psychologyAlt,
            title: "Data-Driven Insights for Institutional Growth",
            description: "Gain real-time analytics to understand student needs and optimize institutional strategies. Campus Insights offers intuitive dashboards that provide actionable insights to improve student outcomes and drive growth.",
            image: campusInsightsFeature3
        },
        {
            icon: comicBubble,
            title: "Administrative Automation",
            description: "Streamline operations with AI-driven automation for financial aid management, documentation, and 24/7 chatbot support. By reducing manual workloads, staff can focus on strategic initiatives and enhance student engagement.",
            image: campusInsightsFeature4
        },
    ], [])

    return (
        <div className='campus-insights-screen'>
            <TopContainer
                background={campusInsightsTopBackground}
                routes={["Products", "Campus Insights"]}
                title={topbarTitle}
                description={topbarDescription}
                buttonTitle="Visit Website"
                onButtonClick={() => window.open("https://campusinsights.ai/", "_blank")}
            />
            <div className="campus-insights-content-container">
                <div className="content-wrapper">
                    <KeyFeatures title='Key Features of Campus Insights' data={featuresData} />
                </div>
            </div>
            <div className="industries-wrapper">
                <Industries />
            </div>
            <div className="campus-insights-content-container">
                <div className="content-wrapper">
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CampusInsights;
import { useMemo } from 'react';
import './index.scss';
import healthcareTopBackground from "../../../assets/images/healthcare_top_background.png";
import emsPal from "../../../assets/images/ems_pal_1.png"
import campaignsPlanet from "../../../assets/images/campaigns_planet.png"
import TopContainer from '../../../components/topContainer';
import Challenges from '../components/challenges';
import Solutions from '../components/solutions';
import RelatedProducts from '../components/relatedProducts';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';

const HealthCare = () => {

    const topbarTitle = useMemo(() => 'Transforming Healthcare with Collaborative AI', []);
    const topbarDescription = useMemo(() => 'Our mission is to advance AI-driven diagnostics and deliver personalized care solutions that transform patient outcomes. We equip healthcare providers with precise, data-driven insights to optimize treatment plans and ensure equitable access to high-quality care for all.', []);

    const challenges = useMemo(() => [
        {
            title: "Data Overload & Silos",
            description: "Healthcare generates massive amounts of data from various sources, but this data often resides in silos, limiting effective analysis and insights."
        },
        {
            title: "Resource Constraints",
            description: "Escalating healthcare costs and limited budgets strain resources, impacting access to care and the ability to invest in cutting-edge technologie."
        },
        {
            title: "Patient Engagement",
            description: "Enhance communication and education to empower patients in their care."
        },
        {
            title: "Integration of Legacy Systems",
            description: "Modernize outdated systems for better performance and scalability."
        },
    ], [])

    const solutions = useMemo(() => [
        {
            title: "Improved Patient Care",
            description: "AI-powered solutions can improve patient outcomes by enabling earlier disease detection, personalized treatment plans, and more effective care coordination."
        },
        {
            title: "Streamlined Workflows",
            description: "By automating routine tasks and streamlining workflows, Sentellent empowers healthcare providers to focus on patient care and improve operational efficiency."
        },
        {
            title: "Regulatory Compliance",
            description: "AI-powered monitoring tools help healthcare organizations stay compliant with evolving regulations, reducing risks and ensuring adherence to industry standards."
        },
        {
            title: "Enhanced Patient Outcomes",
            description: "By improving diagnosis, treatment, and healthcare quality, Sentellent enhances patient outcomes, reducing mortality, hospital stays, and improving quality of life."
        },
    ], [])

    const productItems = useMemo(() => [
        {
            name: "EMSPal",
            summary: "EMSPal is an AI-powered chat assistant that empowers EMTs and paramedics with instant access to critical protocols, enabling faster and more informed emergency responses.",
            image: emsPal,
            path: "https://emspal.com/"
        },
        {
            name: "Campaigns Planet",
            summary: "The CampaignsPlanet AI-driven Marketing Automation platform is designed to revolutionize the management of marketing campaigns.",
            image: campaignsPlanet,
            path: "https://campaignsplanet.com/"
        },
    ], []);

    return (
        <div className='healthcare-screen'>
            <TopContainer background={healthcareTopBackground} routes={["Industries", "Healthcare Industry"]} title={topbarTitle} description={topbarDescription} />
            <Challenges data={challenges} />
            <Solutions data={solutions} />
            <div className="healthcare-content-container">
                <div className="content-wrapper">
                    <RelatedProducts data={productItems} />
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HealthCare;
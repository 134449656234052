import { memo, useMemo, useState, useRef, useEffect } from 'react';
import './index.scss';
import corouselIcon1 from "../../../assets/images/corousel_icon_1.svg";
import corouselIcon2 from "../../../assets/images/corousel_icon_2.svg";
import corouselIcon3 from "../../../assets/images/corousel_icon_3.svg";
import corouselIcon4 from "../../../assets/images/corousel_icon_4.svg";
import corouselIcon5 from "../../../assets/images/corousel_icon_5.svg";
import westArrowBlack from "../../../assets/images/west_arrow_black.svg";
import eastArrowBlack from "../../../assets/images/east_arrow_black.svg";

const BusinessSolutions = () => {

  const scrollRef = useRef(null);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  const corouselItems = useMemo(() => [
    {
      title: "Cross-Industry Domain Expertise",
      description:
        "Deep knowledge across multiple sectors, including Education, healthcare, supply chain, and Entertainment.",
      icon: corouselIcon1,
    },
    {
      title: "Proven Track Record in AI Value Creation",
      description:
        "History of delivering transformative AI solutions tailored to customer needs that drive tangible business outcomes.",
      icon: corouselIcon2,
    },
    {
      title: "Flexible and Future-Proof AI Solutions",
      description:
        "Model-agnostic approach ensures that our AI tools remain adaptable and relevant in a rapidly evolving technological landscape.",
      icon: corouselIcon3,
    },
    {
      title: "ROI-Focused Implementation",
      description:
        "Prioritize delivering measurable returns on AI investments.",
      icon: corouselIcon4,
    },
    {
      title: "AI Governance and Security",
      description:
        "State-of-the-art explainable AI (XAI) techniques for transparent decision-making, Rigorous data privacy measures, Ethical AI development practice and security.",
      icon: corouselIcon5
    }
  ], [])

  const checkScrollPosition = () => {
    setTimeout(() => {
      const scrollLeft = scrollRef.current.scrollLeft;
      const scrollWidth = scrollRef.current.scrollWidth;
      const containerWidth = scrollRef.current.offsetWidth;

      setPrevDisabled(scrollLeft === 0);
      setNextDisabled(scrollLeft + containerWidth >= scrollWidth);
    }, 500)
  };

  const handlePrev = () => {
    if (!prevDisabled) {
      scrollRef.current.scrollBy({
        left: -window.innerWidth * 0.85,
        behavior: 'smooth',
      });
      checkScrollPosition();
    }
  };

  const handleNext = () => {
    if (!nextDisabled) {
      scrollRef.current.scrollBy({
        left: window.innerWidth * 0.85,
        behavior: 'smooth',
      });
      checkScrollPosition();
    }
  };

  useEffect(() => {
    checkScrollPosition();
  }, [])

  return (
    <div className='home-business-solutions'>
      <div className='title-container'>
        <div className='title-container-title'>AI-Driven Business Solutions</div>

        <p className='title-container-description'>
          Sentellent offers a comprehensive range of AI consulting services
          tailored to meet the unique needs of businesses.
        </p>
      </div>
      <div className="corousel-container">
        <div className="scroll-container" ref={scrollRef}>
          {
            corouselItems.map((corouselItem, corouselItemindex) => (
              <div className='corousel-card' key={corouselItemindex.toString()}>
                <img src={corouselItem.icon} alt='' loading='lazy' />
                <div className='corousel-title'>{corouselItem.title}</div>
                <div className='corousel-description'>{corouselItem.description}</div>
              </div>
            ))
          }
        </div>
      </div>
      <div className='arrow-container'>
        <div className={`arrow ${prevDisabled ? 'inactive' : ''} `} onClick={handlePrev} >
          <img src={westArrowBlack} alt='' loading='lazy' />
        </div>
        <div className={`arrow ${nextDisabled ? 'inactive' : ''} `} onClick={handleNext} >
          <img src={eastArrowBlack} alt='' loading='lazy' />
        </div>
      </div>
    </div>
  );
};

export default memo(BusinessSolutions);

import { useMemo } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import homeTopbackground from "../../assets/images/home_top_background.png";
import TopContainer from "../../components/topContainer";
import BusinessSolutions from "./businessSolutions";
import Products from "./products";
// import Testimonial from "./testimonial";
import GradeSecurity from "./gradeSecurity";
import BlogsNews from "../../components/blogsNews";
import Footer from "../../components/footer";

const Home = () => {

    const navigate = useNavigate();

    const topbarTitle = useMemo(() => 'A Generative AI Product and Services Company', []);
    const topbarDescription = useMemo(() => 'Our mission is to be a strategic partner in navigating the generative AI landscape, helping businesses of all sizes stay at the forefront of technological innovation, create tangible value and deliver competitive advantage.', []);

    const onTopbarButtonClick = ()=>{
        navigate("/about-us")
    }

    return (
        <div className="home-screen">
            <TopContainer background={homeTopbackground} title={topbarTitle} description={topbarDescription} buttonTitle="About Us" onButtonClick={onTopbarButtonClick} />
            <BusinessSolutions />
            <div className="products-wrapper">
                <Products />
            </div>
            <div className="home-content-wrapper">
                <div className="home-content-container">
                    {/* <Testimonial /> */}
                    <GradeSecurity />
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;
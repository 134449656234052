import { memo } from 'react';
import './index.scss';
import eastArrow from "../../../../assets/images/east_arrow_white.svg";

const RelatedProducts = ({ data = [] }) => {

    const handleNavigate = (path) => {
        if (path) {
            window.open(path, "_blank");
        }
    }

    return (
        <div className='related-products'>
            <div className='title'>Related Products</div>
            <div className='images-container'>
                {data.map((item, index) => (
                    <div className='image-card' key={index.toString()} >
                        <div className='image-content'>
                            <div className="card-title">
                                {item.name}
                            </div>
                            <div className="hovered-content">
                                <div className="hovered-content-title">
                                    {item.name}
                                </div>
                                <div className="hovered-content-summary">
                                    {item.summary}
                                </div>
                                <button onClick={() => { handleNavigate(item.path) }}>
                                    <span>Visit Website</span>
                                    <img src={eastArrow} alt='' loading='lazy' />
                                </button>
                            </div>
                        </div>
                        <img className='background-image' src={item.image} alt='' loading='lazy' draggable={false} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(RelatedProducts);
import { memo, useMemo, useState, useRef, useEffect } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import eastArrow from "../../../../assets/images/east_arrow_white.svg";
import education from "../../../../assets/images/education.png";
import healthcare from "../../../../assets/images/healthcare.png";
import supplyChain from "../../../../assets/images/supply_chain.png";
import mediaEntertainment from "../../../../assets/images/media_entertainment.png";
import westArrowBlack from "../../../../assets/images/west_arrow_black.svg";
import eastArrowBlack from "../../../../assets/images/east_arrow_black.svg";

const Industries = () => {

    const navigate = useNavigate();

    const scrollRef = useRef(null);
    const [prevDisabled, setPrevDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const productItems = useMemo(() => [
        {
            name: "Education",
            summary: "Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.",
            image: education,
            path: "/industries/education"
        },
        {
            name: "Healthcare Industry",
            summary: "Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.",
            image: healthcare,
            path: "/industries/healthcare-industry"
        },
        {
            name: "Supply Chain",
            summary: "Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.",
            image: supplyChain,
            path: "/industries/supply-chain"
        },
        {
            name: "Media & Entertainment",
            summary: "Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.",
            image: mediaEntertainment,
            path: "/industries/media-and-entertainment"
        },
    ], [])

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);

    const checkScrollPosition = () => {
        setTimeout(() => {
            const scrollLeft = scrollRef.current.scrollLeft;
            const scrollWidth = scrollRef.current.scrollWidth;
            const containerWidth = scrollRef.current.offsetWidth;

            setPrevDisabled(scrollLeft === 0);
            setNextDisabled(scrollLeft + containerWidth >= scrollWidth);
        }, 500)
    };

    const handlePrev = () => {
        if (!prevDisabled) {
            scrollRef.current.scrollBy({
                left: -window.innerWidth * 0.87,
                behavior: 'smooth',
            });
            checkScrollPosition();
        }
    };

    const handleNext = () => {
        if (!nextDisabled) {
            scrollRef.current.scrollBy({
                left: window.innerWidth * 0.87,
                behavior: 'smooth',
            });
            checkScrollPosition();
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    }

    useEffect(() => {
        checkScrollPosition();
    }, [])

    return (
        <div className='products-industries'>
            <div className='title'>Our Industries</div>
            <div className='scroll-container' ref={scrollRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} >
                <div className='images-container'>
                    {productItems.map((item, index) => (
                        <div className='image-card' key={index.toString()} >
                            <div className='image-content'>
                                <div className="card-title">
                                    {item.name}
                                </div>
                                <div className="hovered-content">
                                    <div className="hovered-content-title">
                                        {item.name}
                                    </div>
                                    <div className="hovered-content-summary">
                                        {item.summary}
                                    </div>
                                    <button onClick={() => { handleNavigate(item.path) }}>
                                        <span>Learn More</span>
                                        <img src={eastArrow} alt='' loading='lazy' />
                                    </button>
                                </div>
                            </div>
                            <img className='background-image' src={item.image} alt='' loading='lazy' draggable={false} />
                        </div>
                    ))}
                </div>
            </div>
            <div className='arrow-container'>
                <div className={`arrow ${prevDisabled ? 'inactive' : ''} `} onClick={handlePrev} >
                    <img src={westArrowBlack} alt='' loading='lazy' />
                </div>
                <div className={`arrow ${nextDisabled ? 'inactive' : ''} `} onClick={handleNext} >
                    <img src={eastArrowBlack} alt='' loading='lazy' />
                </div>
            </div>
        </div>
    );
};

export default memo(Industries);
import { memo, useMemo } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import Button from '../button';
import navbarLogo from "../../assets/images/navbar_logo.svg";
import northArrowBlack from "../../assets/images/north_arrow_black.svg"

const Footer = () => {

  const menuLinkItems = useMemo(() => [
    {
      category: "Industries",
      submenu: [
        {
          name: "Education",
          path: "/industries/education"
        },
        {
          name: "Healthcare Industry",
          path: "/industries/healthcare-industry"
        },
        {
          name: "Supply Chain",
          path: "/industries/supply-chain"
        },
        {
          name: "Media & Entertainment",
          path: "/industries/media-and-entertainment"
        },
      ]
    },
    {
      category: "Products",
      submenu: [
        {
          name: "Campus Insights",
          path: "/products/campus-insights"
        },
        {
          name: "EMSPal",
          path: "/products/emspal"
        },
        {
          name: "Jammin.ai",
          path: "/products/jammin-ai"
        },
        {
          name: "Campaigns Planet",
          path: "/products/campaigns-planet"
        },
        // {
        //   name: "easy-donate",
        //   path: "/products/easy-donate"
        // },
      ]
    },
    {
      category: "Quick Links",
      submenu: [
        {
          name: "About Us",
          path: "/about-us"
        },
        {
          name: "Blogs",
          path: "/blogs"
        },
        {
          name: "Contact Us",
          path: "/contact-us"
        },
      ]
    }
  ], []);

  const bookAppoinment = () => {
    window.open("https://calendly.com/sentellent/30min", "_blank")
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className='app-footer'>
      <div className="footer-top">
        <div className='footer-title'>
          Let's work together to unlock the full potential of Generative AI for your business.
        </div>
        <Button label="Book an Appointment" onClick={bookAppoinment} />
      </div>
      <div className="footer-bottom">
        <div className='sentellent-content'>
          <div className='sentellent-title'>
            <Link to="/"><img className='sentellent-logo' src={navbarLogo} alt='' loading='lazy' /></Link>
          </div>
          <div className="sentellent-summary">
            Sentellent offers a comprehensive suite of key services designed to facilitate the effective integration of AI into your business processes.
          </div>
        </div>
        <div className="menu-container">
          {menuLinkItems.map((menuLinkItem, menuLinkItemIndex) => (
            <div className='category-container' key={menuLinkItemIndex.toString()}>
              <div className="category-title">{menuLinkItem.category}</div>
              {menuLinkItem.submenu.map((linkItem, linkItemIndex) => (
                <Link key={linkItemIndex.toString()} to={linkItem.path} className='menu-link'>{linkItem.name}</Link>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="copyright-wrapper">
        <div className="copyright-container">
          <div className='copyright-title'>© 2025 SENTELLENT. All Rights Reserved</div>
          <div className='uparrow-button' onClick={scrollToTop} >
            <img src={northArrowBlack} alt='' loading='lazy' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
import { memo, useMemo, useState, useRef, useEffect } from 'react';
import './index.scss';
import corouselIcon1 from "../../../assets/images/corousel_icon_1.svg";
import corouselIcon2 from "../../../assets/images/corousel_icon_2.svg";
import corouselIcon3 from "../../../assets/images/corousel_icon_3.svg";
import corouselIcon4 from "../../../assets/images/corousel_icon_4.svg";
import corouselIcon5 from "../../../assets/images/corousel_icon_5.svg";
import westArrowBlack from "../../../assets/images/west_arrow_black.svg";
import eastArrowBlack from "../../../assets/images/east_arrow_black.svg";

const Expertise = () => {

  const scrollRef = useRef(null);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  const corouselItems = useMemo(() => [
    {
      title: "Large Language Models (LLMs) and Agentic AI",
      description:
        "We specialize in integrating LLMs and agentic AI to enhance decision-making and automate complex workflows, driving innovation and efficiency across industries.",
      icon: corouselIcon1,
    },
    {
      title: "Vector and Traditional Database Integration for RAG Systems",
      description:
        "Our team excels at building RAG systems by seamlessly integrating vector and traditional databases, optimizing data utilization and model accuracy for real-time insights.",
      icon: corouselIcon2,
    },
    {
      title: "AI Security and Governance",
      description:
        "We prioritize state-of-the-art AI security practices, ensuring transparent decision-making through explainable AI (XAI) and rigorous data privacy measures that adhere to industry standards.",
      icon: corouselIcon3,
    },
    {
      title: "Cross-Industry Domain Expertise",
      description:
        "With deep knowledge across sectors like education, healthcare, supply chain, and entertainment, we tailor AI solutions to specific industry needs, delivering transformative outcomes.",
      icon: corouselIcon4,
    },
    {
      title: "ROI-Focused Implementation and Model-Agnostic Approach",
      description:
        "Our model-agnostic approach ensures adaptability and relevance in a rapidly evolving landscape, prioritizing measurable returns on AI investments and future-proof solutions.",
      icon: corouselIcon5
    }
  ], [])

  const checkScrollPosition = () => {
    const scrollLeft = scrollRef.current.scrollLeft;
    const scrollWidth = scrollRef.current.scrollWidth;
    const containerWidth = scrollRef.current.offsetWidth;

    setPrevDisabled(scrollLeft === 0);
    setNextDisabled(scrollLeft + containerWidth >= scrollWidth);
  };

  const handlePrev = () => {
    if (!prevDisabled) {
      scrollRef.current.scrollBy({
        left: (scrollRef.current.offsetWidth) * -1,
        behavior: 'smooth',
      });
      checkScrollPosition();
    }
  };

  const handleNext = () => {
    if (!nextDisabled) {
      scrollRef.current.scrollBy({
        left: scrollRef.current.offsetWidth,
        behavior: 'smooth',
      });
      checkScrollPosition();
    }
  };

  useEffect(() => {
    checkScrollPosition();
  }, [])

  return (
    <div className='about-expertise'>
      <div className='title-container'>
        <div className='title-container-title'>Our Expertise</div>

        <p className='title-container-description'>
        At Sentellent, we pride ourselves on our comprehensive expertise in delivering cutting-edge AI solutions. Our capabilities span multiple domains and technologies, ensuring that our clients receive tailored solutions that meet their unique business needs.
        </p>
      </div>
      <div className="corousel-container">
        <div className="scroll-container" ref={scrollRef}>
          {
            corouselItems.map((corouselItem, corouselItemindex) => (
              <div className='corousel-card' key={corouselItemindex.toString()}>
                <img src={corouselItem.icon} alt='' loading='lazy' />
                <div className='corousel-title'>{corouselItem.title}</div>
                <div className='corousel-description'>{corouselItem.description}</div>
              </div>
            ))
          }
        </div>
      </div>
      <div className='arrow-container'>
        <div className={`arrow ${prevDisabled ? 'inactive' : ''} `} onClick={handlePrev} >
          <img src={westArrowBlack} alt='' loading='lazy' />
        </div>
        <div className={`arrow ${nextDisabled ? 'inactive' : ''} `} onClick={handleNext} >
          <img src={eastArrowBlack} alt='' loading='lazy' />
        </div>
      </div>
    </div>
  );
};

export default memo(Expertise);

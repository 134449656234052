import { memo, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import navbarLogo from "../../assets/images/navbar_logo.svg";
import arrowDownWhite from "../../assets/images/arrow_down_white.svg";
import menuToggle from "../../assets/images/menu_toggle.svg";
import closeIcon from "../../assets/images/close_icon.svg";
import Button from '../button';

const Navbar = () => {

    const [isDeskTop, setIsDeskTop] = useState(window.innerWidth >= 1024);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [focusedMenu, setFocusedMenu] = useState(null)
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        setSidebarVisible(false);
        navigate(path);
    }

    const menuItems = useMemo(() => [
        {
            name: "Industries",
            subMenus: [
                {
                    name: "Education",
                    path: "/industries/education"
                },
                {
                    name: "Healthcare Industry",
                    path: "/industries/healthcare-industry"
                },
                {
                    name: "Supply Chain",
                    path: "/industries/supply-chain"
                },
                {
                    name: "Media & Entertainment",
                    path: "/industries/media-and-entertainment"
                },
            ]
        },
        {
            name: "Products",
            subMenus: [
                {
                    name: "Campus Insights",
                    path: "/products/campus-insights"
                },
                {
                    name: "EMSPal",
                    path: "/products/emspal"
                },
                {
                    name: "Jammin.ai",
                    path: "/products/jammin-ai"
                },
                {
                    name: "Campaigns Planet",
                    path: "/products/campaigns-planet"
                },
                // {
                //     name: "easy-donate",
                //     path: "/products/easy-donate"
                // },
            ]
        },
        {
            name: "About Us",
            path: "/about-us"
        },
        {
            name: "Blogs",
            path: "/blogs"
        },
        {
            name: "Contact Us",
            path: "/contact-us"
        }
    ], [])

    const toggleSubMenu = (index) => {
        setFocusedMenu(prev => prev === index ? null : index)
    }

    const bookAppoinment = () => {
        window.open("https://calendly.com/sentellent/30min", "_blank")
    }

    useEffect(() => {
        const handleResize = () => {
            setIsDeskTop(window.innerWidth >= 1024);
            if (window.innerWidth < 1024) {
                setSidebarVisible(false);
            }
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <div className='app-navbar'>
            <Link to="/"><img className='logo' src={navbarLogo} alt='' loading='lazy' /></Link>
            {isDeskTop ? (
                <>
                    <div className='menu-links'>
                        {menuItems.map((menuItem, menuItemKey) => (
                            <div className='menu-item-container' key={menuItemKey.toString()}>
                                <div>

                                    {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
                                        <div className='menu-item'
                                            tabIndex={0}
                                            onClick={() => toggleSubMenu(menuItemKey)}
                                            onBlur={(e) => {
                                                if (!e.relatedTarget || !e.relatedTarget.closest('.submenu-items-container')) {
                                                    toggleSubMenu(null);
                                                }
                                            }}
                                        >
                                            <span>{menuItem.name}</span>
                                            <span className={`${menuItemKey === focusedMenu ? 'active' : ''}`} >
                                                <img src={arrowDownWhite} alt='' loading='lazy' />
                                            </span>
                                        </div>
                                    ) : (
                                        <Link to={menuItem.path}>
                                            {menuItem.name}
                                        </Link>
                                    )}
                                </div>
                                {menuItem.subMenus && menuItem.subMenus.length > 0 && menuItemKey === focusedMenu && (
                                    <div className="submenu-items-container">
                                        {menuItem.subMenus.map((subMenuItem, subMenuItemIndex) => (
                                            <div className='submenu-item' key={subMenuItemIndex.toString()}>
                                                <Link to={subMenuItem.path} onMouseDown={(e) => e.preventDefault()} >
                                                    {subMenuItem.name}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <Button label="Book an Appointment" onClick={bookAppoinment} />
                </>
            ) : (
                <>
                    <div className='menu-toggle-icon' tabIndex={0} onClick={() => { setSidebarVisible(true) }} >
                        <img src={menuToggle} alt='' loading='lazy' />
                    </div>
                    {sidebarVisible && (
                        <div className="sidebar">
                            <div className="mobile-sidebar-header">
                                <Link to="/"><img className='logo' src={navbarLogo} alt='' loading='lazy' /></Link>
                                <div className='close-icon' onClick={() => { setSidebarVisible(false) }}>
                                    <img src={closeIcon} alt='' loading='lazy' />
                                </div>
                            </div>
                            <div className="links-wrapper">
                                {menuItems.map((menuItem, menuItemKey) => (
                                    <div className='mobile-menu-links-container' key={menuItemKey.toString()}>
                                        {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
                                            <div className='mobile-menu-link' tabIndex={0} onClick={() => toggleSubMenu(menuItemKey)}>
                                                <span>
                                                    {menuItem.name}
                                                </span>
                                                {(
                                                    <span className={`toggleicon ${menuItemKey === focusedMenu ? 'active' : ''}`} >
                                                        <img src={arrowDownWhite} alt='' loading='lazy' />
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <div className='mobile-menu-link' tabIndex={0} onClick={() => handleNavigate(menuItem.path)}>
                                                <span>
                                                    {menuItem.name}
                                                </span>
                                            </div>
                                        )}
                                        {menuItem.subMenus && menuItem.subMenus.length > 0 && menuItemKey === focusedMenu && (
                                            <div className="mobile-submenu-link-container">
                                                {menuItem.subMenus.map((subMenuItem, subMenuItemIndex) => (
                                                    <div className='mobile-submenu-link' key={subMenuItemIndex.toString()} onClick={() => { handleNavigate(subMenuItem.path) }}>{subMenuItem.name}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className='button-container'>
                                <Button label="Book an Appointment" onClick={bookAppoinment} />
                            </div>
                        </div>
                    )}
                </>
            )
            }


        </div >
    );
};

export default memo(Navbar);
import { useMemo } from 'react';
import './index.scss';
import emaspalTopBackground from "../../../assets/images/emspal_top_background.png"
import emspalFeature1 from "../../../assets/images/emspal_feature_1.png"
import emspalFeature2 from "../../../assets/images/emspal_feature_2.png"
import emspalFeature3 from "../../../assets/images/emspal_feature_3.png"
import emspalFeature4 from "../../../assets/images/emspal_feature_4.png"
import assistantNavigation from "../../../assets/images/assistant_navigation.svg"
import emojiLanguage from "../../../assets/images/emoji_language.svg"
import moveIcon from "../../../assets/images/move_icon.svg"
import stackedIcon from "../../../assets/images/stacked_icon.svg"
import TopContainer from '../../../components/topContainer';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import KeyFeatures from '../components/keyfeatures';
import Industries from '../components/industries';

const EmsPal = () => {

    const topbarTitle = useMemo(() => 'EMSPal: Elevating Emergency Response with AI', []);
    const topbarDescription = useMemo(() => 'EMSPal is an AI-powered chat assistant designed to revolutionize emergency medical services. The tool provides instant access to critical local protocols, enabling EMS personnel to quickly and efficiently respond to emergencies. With EMSPal, EMTs and paramedics always have the right information at their fingertips, ensuring timely and accurate patient care while streamlining workflows and reducing errors.', []);

    const featuresData = useMemo(() => [
        {
            icon: assistantNavigation,
            title: "Real-Time, Location-Specific Information",
            description: "Access real-time, location-specific EMS protocols with a single tap, ensuring you always have the most up-to-date information.",
            image: emspalFeature1
        },
        {
            icon: emojiLanguage,
            title: "Natural Language Processing",
            description: "Ask questions in plain language and receive clear, concise answers, facilitating efficient access to complex information",
            image: emspalFeature2
        },
        {
            icon: moveIcon,
            title: "Geolocation Integration",
            description: "Obtain the most relevant protocols based on your location, enabling responders to provide the best possible care in any situation",
            image: emspalFeature3
        },
        {
            icon: stackedIcon,
            title: "Automated Resource Allocation",
            description: "Optimize resource deployment based on the urgency and nature of the emergency, ensuring timely and efficient assistance.",
            image: emspalFeature4
        },
    ], [])

    return (
        <div className='emspal-screen'>
            <TopContainer
                background={emaspalTopBackground}
                routes={["Products", "EMSPal"]}
                title={topbarTitle}
                description={topbarDescription}
                buttonTitle="Visit Website"
                onButtonClick={() => window.open("https://emspal.com/", "_blank")}
            />
            <div className="emspal-content-container">
                <div className="content-wrapper">
                    <KeyFeatures title='Key Features of EMSPal' data={featuresData} />
                </div>
            </div>
            <div className="industries-wrapper">
                <Industries />
            </div>
            <div className="emspal-content-container">
                <div className="content-wrapper">
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EmsPal;
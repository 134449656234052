import './index.scss';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/navbar';
import westArrowWhite from "../../../assets/images/west_arrow_white.svg";
import blog3DetailImage1 from "../../../assets/images/blog3_detail_image_1.png";
import blog3DetailImage2 from "../../../assets/images/blog3_detail_image_2.png";
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';
import ScrollToTop from '../../../components/scrollToTop';

const Blog3 = () => {

    const navigation = useNavigate();

    return (
        <div className='blog-detail-screen'>
            <div className='blog-top-container'>
                <Navbar />
                <div className="blog-content-area">
                    <button className="topbar-button" onClick={() => { navigation(-1) }} >
                        <img src={westArrowWhite} alt='' loading='lazy' />
                        <span>Back to Blog</span>
                    </button>
                    <div className="topbar-title">
                        Coding by Conversation: The AI Revolution in Software Development
                    </div>
                    <div className="topbar-date">
                        April 05, 2025
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <div className="blog-content-wrapper">
                <div className="blog-content-container">
                    <div>
                        <div className="title">
                            The Paradigm Shift from Traditional Code Generation to Conversation-Driven Code Generation and Implications on Learning
                        </div>
                        <p>
                            Conversational AI and generative language models are reshaping the programming landscape, transforming coding from a technical skill into a collaborative, iterative process where developers interact with AI to generate code. The Generative AI Entrepreneurship Program that is currently being created at California Community Colleges leverages this paradigm, enabling students to create applications through dialogue with AI rather than traditional coding. This article explores the program's curriculum, which shifts the emphasis from deep coding expertise to architectural understanding and iterative refinement through conversational prompts. Students engage in a dynamic, feedback-driven development cycle, producing applications without extensive programming knowledge. This approach democratizes coding, making entrepreneurship in technology more accessible.
                        </p>
                    </div>
                    <div>
                        <div className="title">
                            From Coding to Conversational Engineering
                        </div>
                        <p>
                            In conversation-driven code generation, developers guide AI with prompts, effectively transitioning from writing syntax to specifying requirements. Through iterative interaction, the developer provides feedback and adjusts prompts until the generated code meets the desired functionality. This new role—often referred to as "conversation engineering"—focuses on defining what the code should accomplish and refining responses rather than manually creating code structures. By guiding AI through prompt iterations, developers can quickly realize their ideas without needing extensive coding knowledge, positioning them to focus on the functionality and user experience of their applications.
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog3DetailImage1} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="title">The Iterative Process of Conversational Code Development</div>
                        <div className="list">
                            <div className="list-row">
                                <div>
                                    <p>
                                        An essential part of conversation-driven development is learning to iteratively adjust prompts to guide AI in generating and refining code. This iterative prompt engineering process mirrors a test-driven approach, where the developer continually tests outputs and revises prompts until achieving the desired solution. Based on a conversational exchange with <a href='https://claude.ai/' target='_blank' >Claude.ai</a>, this process can be broken down into key stages:
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Defining Initial Requirements:For example, a student might start with a simple request: “Create a coin toss simulator using HTML.” This prompt outlines the fundamental idea but leaves details open to interpretation.The AI may generate a basic simulator, which the student reviews for accuracy.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Testing and Refining Prompts:After reviewing the output, the student assesses how well the AI's response aligns with their needs. Suppose the initial code lacks a custom input field for a number of tosses; the student might adjust the prompt to specify this need.For instance, “Modify the simulator to include a custom input field where I can enter the number of tosses” yields a more tailored result. Through this refinement, students learn the value of precise, detailed instructions to guide AI outputs more accurately.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Evaluating Functionality:Each iteration offers an opportunity to test the generated code's functionality. If errors arise—such as input validation issues or incorrect calculations—the student addresses these by clarifying the prompt.For example, a prompt like “Ensure input values are between 1 and 1,000,000 and display an error for invalid inputs” addresses a specific functional requirement and enhances the application's robustness.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Expanding Capabilities Through Additional Features:As the application nears completion, students may seek to add more features or enhance the user experience. In the <a href='https://claude.ai/' target='_blank' >Claude.ai</a> example, this could involve adding graphical elements or real-time statistics. By iteratively prompting the AI to build on its previous output, students learn to develop applications that grow in complexity and functionality, emphasizing problem-solving and adaptability.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Deploying and Troubleshooting:The program includes deploying AI-generated applications on cloud platforms like AWS, teaching students to troubleshoot deployment issues. Students may ask AI for deployment steps, iterating prompts to resolve specific errors, ensuring they understand basic cloud hosting and terminal commands.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="image-wrapper">
                            <img src={blog3DetailImage2} alt='' loading='lazy' />
                        </div>
                    </div>
                    <div>
                        <div className="title">
                            Step-by-Step: Iteratively Building an Application Through Prompts
                        </div>
                        <div className='list'>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Let's walk through an example application built through iterative prompts—developing a coin toss simulator and a backend API using FastAPI. Here's how the process unfolds:
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">
                                        Starting with a Basic Concept
                                    </div>
                                    <p>
                                        <span>Initial Prompt:</span> “Create a coin toss simulator using vanilla HTML that I can use to show my students the law of large numbers in probability.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        In response to this initial prompt, the AI generates a basic HTML page that includes multiple toss options (single toss, 10 tosses, 100 tosses, etc.), a reset button, and statistics showing heads, tails, and current proportion. A live graph displays the running proportion of heads, helping students understand how the proportion stabilizes around 0.5 over time.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Review and Next Steps:</span> While the simulator meets the basic requirement, the user realizes they need more flexibility in the number of tosses to better demonstrate probability concepts. This leads to a refinement prompt.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">
                                        Adding Customizability Through Prompt Refinement
                                    </div>
                                    <p>
                                        <span>Refinement Prompt:</span> “Modify the simulator to include a custom input field where I can manually enter the number of tosses.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI then updates the simulator, adding an input field for custom tosses, validation (ensuring the input is a number between 1 and 1,000,000), and an error message for invalid inputs. It keeps the original quick-access buttons, providing flexibility and ease of use.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Outcome:</span> Now, the simulator can handle custom input, enhancing its educational value by allowing users to experiment with different numbers of tosses. The student can now better illustrate the law of large numbers, seeing how the output converges toward the expected probability with larger samples.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Expanding Functionality: Building a Backend API</div>
                                    <p>
                                        The user decides to extend the application with a backend API using FastAPI to accept user prompts and return AI-generated responses. This API will enable more complex queries, such as statistical analysis and probability explanations.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Prompt to Create Backend: </span>“Generate an OpenAI FastAPI-based Python application that will accept a user prompt and return a response using LlamaIndex. Name the endpoint process_user_prompt.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI generates the basic FastAPI structure with an endpoint /process_user_prompt that accepts user inputs and returns AI-generated responses. It also provides instructions for setting up the environment, configuring dependencies, and running the API.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Troubleshooting and Iteration: After setting up, the user encounters dependency issues, leading to a new prompt.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Troubleshooting Through Iterative Prompts</div>
                                    <p>
                                        <span>Prompt for Troubleshooting: </span> “Fix the dependency conflicts with FastAPI and Pydantic for Python 3.11.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI suggests creating a new virtual environment and specifies compatible package versions. By iteratively refining the environment setup, the user can now run the API successfully, allowing interaction between the simulator's front end and the new backend.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Testing and Adjustments: </span>The user tests the API but encounters issues with output format and response structure. This necessitates further refinement.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Refinement Prompt: </span>“Make sure the response from the OpenAI assistant is included in the API response for process_user_prompt.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI updates the API to return both the original prompt and the assistant's response in a structured format, ensuring the user receives the desired output. This iterative adjustment highlights the importance of clear requirements and prompt specificity in achieving functional results.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Adding a Front-End Interface</div>
                                    <p>
                                        To provide a user-friendly interface for interacting with the backend API, the user prompts the AI to create a simple HTML-based chat interface.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Prompt for Front-End: “Create an HTML chat application that invokes the FastAPI endpoint and displays the AI response in a chat format.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI responds with an HTML file that includes a chat interface, allowing users to enter prompts, submit them to the API, and view the AI's responses in a conversational format. The interface includes a user-friendly input field, response bubbles, and error handling for incomplete or incorrect responses.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        Iterating on UI and Error Handling: During testing, the user encounters CORS (Cross-Origin Resource Sharing) issues, which prevent the front-end from communicating with the API.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>CORS Troubleshooting Prompt: </span>“Add CORS settings to the FastAPI application to allow requests from the HTML chat interface.”
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        The AI updates the FastAPI application with CORS middleware, resolving the communication issue between the front end and the backend.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="title">Key Challenges and Solutions in Iterative Prompting</div>
                        <div className="list">
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Unclear Requirements:</div>
                                    <p>
                                        Initial prompts often result in outputs that do not fully meet expectations. By breaking down requirements and focusing on specific features or fixes, users can refine their prompts to achieve better outcomes.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Example Solution: </span>Instead of a vague prompt like “Make it more interactive,” a focused prompt such as “Add a live graph that updates with each coin toss” helps the AI generate a more targeted solution.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Managing Complexity:</div>
                                    <p>
                                        As applications grow in complexity, managing dependencies, ensuring compatibility, and organizing code become challenging. Users must prompt the AI for dependency resolution, version compatibility, and setup instructions.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Example Solution: </span>If the FastAPI application fails due to version conflicts, a prompt like “Provide compatible versions of FastAPI and Pydantic for Python 3.11” helps resolve these conflicts.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Prompt Specificity and Detail:</div>
                                    <p>
                                        The success of conversation-driven development relies heavily on prompt specificity. Users need to provide clear, concise requirements to avoid ambiguous outputs. Learning to refine prompts based on AI responses becomes a critical skill.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>
                                        <span>Example Solution: </span>If an initial API prompt lacks response detail, users can specify, “Include the response message, prompt tokens, and completion tokens in the API output,” guiding the AI to add the necessary fields.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">New Learning Methods Required for Conversation-Driven Development</div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Prompt Engineering:</div>
                                    <p>
                                        Effective use of conversational AI requires prompt engineering skills. Users must learn to translate functionality into clear prompts, guiding the AI to produce desired outcomes. Educators can support students by teaching them to break down complex requirements into manageable, iterative prompts.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Prompt Engineering:</div>
                                    <p>
                                        Effective use of conversational AI requires prompt engineering skills. Users must learn to translate functionality into clear prompts, guiding the AI to produce desired outcomes. Educators can support students by teaching them to break down complex requirements into manageable, iterative prompts.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Prompt Engineering:</div>
                                    <p>
                                        Effective use of conversational AI requires prompt engineering skills. Users must learn to translate functionality into clear prompts, guiding the AI to produce desired outcomes. Educators can support students by teaching them to break down complex requirements into manageable, iterative prompts.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Iterative Testing and Refinement:</div>
                                    <p>
                                        Users need to test each AI-generated output, identify shortcomings, and refine prompts accordingly. This process mirrors test-driven development, where each prompt serves as a “test case” to validate functionality. Students can develop critical thinking by evaluating AI outputs and iteratively improving the results.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">High-Level Architectural Understanding:</div>
                                    <p>
                                        Since users focus on functionality and output rather than code syntax, a strong understanding of application architecture is essential. Users must comprehend the roles of front-end and back-end components, deployment, and API interactions to guide AI effectively in building each part of the application.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Debugging Through Prompts:</div>
                                    <p>
                                        Troubleshooting in conversation-driven development requires users to diagnose errors and iterate through prompts for resolution. Educators can provide frameworks for identifying and addressing errors in AI-generated code, helping students learn problem-solving in a conversational context.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">What should learning focus on?</div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Understanding Architecture:</div>
                                    <p>
                                        The curriculum introduces web application architecture, including front-end, back-end, APIs, and databases. Students learn to describe these elements in prompts, allowing them to specify detailed requirements and adjust AI outputs based on high-level design needs.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Effective Prompt Engineering:</div>
                                    <p>
                                        A critical aspect of conversational code generation, prompt engineering involves instructing students on writing precise, functional prompts. Rather than delving into syntax, students learn to communicate requirements effectively, iterating prompts based on AI responses.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Terminal and Cloud Deployment Skills:</div>
                                    <p>
                                        Students learn to run, deploy, and monitor applications via terminal commands, IDEs, and cloud tools. This empowers them to operationalize applications independently, understanding practical deployment steps rather than focusing solely on code creation.
                                    </p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Debugging and Error Handling:</div>
                                    <p>
                                        Since AI-generated code can contain errors or omit functionalities, students learn to refine prompts and debug iteratively. Educators provide strategies for troubleshooting and error handling, such as identifying where prompt specificity can improve outputs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">Practical Learning Modules and Case Studies</div>
                            <div className="list-row">
                                <div>
                                    <p>What follows are examples of learning modules and case studies that are incorporated into the program in order to help students to quickly iterate from the ideation phase to the product phase.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>Creating a Basic Web Application:This introductory project guides students through creating a web application using conversational AI. Starting with a basic prompt, students refine features like input fields, error handling, and layout adjustments through multiple prompt iterations, gradually developing a functional application.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>Building AI-Enhanced Features:A second module teaches students to create applications that integrate AI functionalities, such as a chatbot or recommendation system. Through prompts like “Create an endpoint that processes user prompts using FastAPI,” students iteratively refine responses to add and test features.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p>Cloud Deployment and Testing:In this module, students deploy applications on platforms like AWS, troubleshooting deployment errors. By prompting AI for specific deployment instructions and solutions, students gain practical experience in launching applications for end-users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">Educator's Role in Facilitating Conversational Development</div>
                            <div className="list-row">
                                <div>
                                    <p>In conversation-driven programming, educators shift from traditional teaching to facilitation, guiding students as they iterate on AI prompts.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">Key areas of support include:</div>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Prompt Refinement: </span>Educators help students craft effective prompts and refine them to yield more precise outputs.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Architectural Understanding: </span>Educators reinforce key architectural concepts, allowing students to guide AI in building well-structured applications.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Debugging Guidance: </span>Teachers offer frameworks for resolving common errors, helping students identify specific issues in AI-generated code.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Encouraging Iterative Experimentation: </span>Educators foster a growth mindset, encouraging students to test, revise, and learn from each iteration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="list">
                            <div className="title">Measuring Success and Long-Term Goals</div>
                            <div className="list-row">
                                <div>
                                    <p>The program evaluates success by assessing students' ability to create and deploy functional applications, fostering entrepreneurial outcomes, and ensuring diversity in technical learning.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <div className="subtitle">
                                        Key metrics include:
                                    </div>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Application Quality: </span>Tracking the number and complexity of applications deployed, demonstrating students' readiness to bring products to market.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Entrepreneurial Outcomes:</span> Measuring the rate of startups or freelance work among graduates, illustrating the program's impact on self-employment.</p>
                                </div>
                            </div>
                            <div className="list-row">
                                <div>
                                    <p><span>Inclusivity: </span>Promoting diversity, ensuring accessibility for students of various backgrounds and technical abilities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="title">Conclusion</div>
                        <p>
                            Conversational code generation represents a significant shift in software development, transitioning from manual coding to iterative prompt engineering. By guiding students to articulate ideas and refine them through conversational AI, the Generative AI Entrepreneurship Program equips a new generation of entrepreneurs with tools to quickly transform concepts into deployable applications. This approach makes digital entrepreneurship accessible and scalable, democratizing technology by minimizing technical barriers. Educators worldwide can adopt this model to empower students, creating a pathway for the next generation of AI entrepreneurs to bring innovative ideas to market with unprecedented speed and ease.
                        </p>
                    </div>
                    <BlogsNews skipId="3" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blog3;
import { useMemo } from 'react';
import './index.scss';
import TopContainer from '../../components/topContainer';
import contactTopBackground from "../../assets/images/contact_top_background.png";
import Footer from '../../components/footer';
import BlogsNews from '../../components/blogsNews';

const Contactus = () => {

    const topbarTitle = useMemo(() => 'Contact Us', []);
    const topbarDescription = useMemo(() => 'Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.', []);

    return (
        <div className='contact-us-screen'>
            <TopContainer title={topbarTitle} description={topbarDescription} background={contactTopBackground} />
            <div className="contact-content-wrapper">
                <div className="contact-content-container">
                    <div className='ck-form'>
                        <iframe
                            src='https://easy-donate.kit.com/af18b51090'
                            name='contact form'
                        >
                        </iframe>
                    </div>
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contactus;
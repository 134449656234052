import { useMemo } from 'react';
import './index.scss';
import educationTopBackground from "../../../assets/images/education_top_background.png";
import campusInsight from "../../../assets/images/campus_insight.png"
import campaignsPlanet from "../../../assets/images/campaigns_planet.png"
import TopContainer from '../../../components/topContainer';
import Challenges from '../components/challenges';
import Solutions from '../components/solutions';
import RelatedProducts from '../components/relatedProducts';
import BlogsNews from '../../../components/blogsNews';
import Footer from '../../../components/footer';

const Education = () => {

    const topbarTitle = useMemo(() => 'Transforming Campuses with AI Driven Solutions', []);
    const topbarDescription = useMemo(() => 'Our mission is to transform higher education by creating intelligent AI solutions that improve student engagement, provide actionable insights, and drive institutional success in the digital age.', []);

    const challenges = useMemo(() => [
        {
            title: "Complex Navigation & Information Accessibility",
            description: "Many institutions have difficult-to-navigate websites, making it hard for prospective students to find critical information about programs, financial aid, and admissions."
        },
        {
            title: "Enrollment & Retention Challenges",
            description: "Poor user experiences, lack of personalized guidance, and limited engagement lead to missed enrollment opportunities and high dropout rates."
        },
        {
            title: "Administrative Burden & Inefficient Operations",
            description: "Staff members spend too much time answering repetitive questions, taking away from more strategic student support efforts."
        },
        {
            title: "Limited Data-Driven Insights & Decision-Making",
            description: "Many institutions struggle to track website engagement, student behavior, and operational efficiency, making it difficult to optimize recruitment and student services."
        },
    ], [])

    const solutions = useMemo(() => [
        {
            title: "Context-Aware Assistance",
            description: "An intelligent chat interface provides immediate, relevant answers to student inquiries, guides users to key information, and enhances the overall digital experience."
        },
        {
            title: "Seamless Automation",
            description: "Automates administrative tasks such as form submissions, appointment scheduling, and FAQ responses, freeing up staff for higher-value activities."
        },
        {
            title: "Personalized Engagement",
            description: "AI-driven recommendations help students discover the right programs, courses, and career paths based on their interests and goals."
        },
        {
            title: "Actionable Insights",
            description: "Tracks visitor behavior, analyzing student interests, preferences, and online journeys to enable data-driven decision-making."
        },
    ], [])

    const productItems = useMemo(() => [
        {
            name: "Campus Insights",
            summary: "Campus Insights is a comprehensive suite of Intelligent AI solutions that improves student engagement, provides actionable insights, and drives institutional success in the digital age.",
            image: campusInsight,
            path: "https://campusinsights.ai/"
        },
        {
            name: "Campaigns Planet",
            summary: "The CampaignsPlanet AI-driven Marketing Automation platform is designed to revolutionize the management of marketing campaigns.",
            image: campaignsPlanet,
            path: "https://campaignsplanet.com/"
        },
    ], [])

    return (
        <div className='education-screen'>
            <TopContainer background={educationTopBackground} routes={["Industries", "Education"]} title={topbarTitle} description={topbarDescription} />
            <Challenges data={challenges} />
            <Solutions data={solutions} />
            <div className="education-content-container">
                <div className="content-wrapper">
                    <RelatedProducts data={productItems} />
                    <BlogsNews />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Education;